import { Box, Button, Stack, Tooltip, Typography } from "@mui/material"
import axios from "axios"
import React from "react"
import { useState } from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import { formatPrice } from "../../helppers/formatters"
import PricesInput from "../inputs/PricesInput"
import HistoryIcon from "@mui/icons-material/History"
import InfoIcon from "@mui/icons-material/Info"
import moment from "moment"
import ConfirmModal from "../modals/ConfirmModal"
import BidMadeBy from "./BidMadeBy"

export default function CounterActions({
  setLeads,
  lead,
  setProgress,
  getLeads,
}) {
  const { loading, setLoading, setShow, setErr, disabled } = useData()
  const [open, setOpen] = useState(false)
  const [counter, setCounter] = useState()
  const [isOpen, setIsOpen] = useState(false)

  const onAccept = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/accept-counter`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setIsOpen(false)
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onCounter = async () => {
    if (!counter || counter === 0) {
      setErr("Please add a offer amount")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/counter`,
        { counter },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onDeniey = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/deny`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "15% !important" },
          px: { xs: 2, md: 2 },
          py: { xs: 2, md: 1 },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {open ? (
          <Stack
            direction={{ xs: "row", md: "column" }}
            width={"100%"}
            spacing={2}
          >
            <PricesInput
              lable="New Bid"
              onValueChange={setCounter}
              value={counter}
              //sx={{ maxWidth: "140px" }}
              disabled={loading || disabled}
            />
            <Button
              onClick={onCounter}
              disabled={loading || disabled}
              variant="outlined"
            >
              Send
            </Button>
            <Button
              onClick={() => {
                setOpen(false)
              }}
              disabled={loading}
              variant="text"
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        ) : (
          <Stack spacing={2}>
            {lead?.counter && (
              <Stack alignItems="center" spacing={0.5}>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"center"}
                  spacing={1}
                  sx={{
                    color:
                      lead?.counter?.status === "Rejected"
                        ? "#e00909"
                        : "inherit",
                  }}
                >
                  <HistoryIcon sx={{ height: "20px", width: "20px" }} />
                  <Typography
                    fontWeight={500}
                    textAlign={"center"}
                    variant="body2"
                    fontSize={14}
                  >
                    {formatPrice(lead?.counter?.amount)} {lead?.counter?.status}
                  </Typography>
                  {lead?.counter?.status === "Pending" && (
                    <Tooltip
                      title="Waiting for sellers response"
                      placement="top-end"
                    >
                      <InfoIcon
                        fontSize="15px"
                        color="primary"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                </Stack>
                <Typography
                  fontWeight={500}
                  textAlign={"center"}
                  variant="body2"
                  fontSize={12}
                  sx={{
                    color:
                      lead?.counter?.status === "Rejected"
                        ? "#e00909"
                        : "inherit",
                  }}
                >
                  {moment(lead?.counter?.createdAt).format("MM/DD/YY @ h A")}
                </Typography>
                <Typography
                  fontWeight={500}
                  textAlign={"center"}
                  variant="body2"
                  color={"#0059ff"}
                >
                  {["API", "System"].includes(lead?.counter?.sentFrom) ? (
                    "Bid by API"
                  ) : (
                    <BidMadeBy by={lead?.counter?.sentBy} />
                  )}
                </Typography>
              </Stack>
            )}
            <Stack
              direction={{ xs: "row", md: "column" }}
              flexWrap="wrap"
              rowGap={1}
              columnGap={2}
            >
              <Button
                sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
                onClick={() => setIsOpen(true)}
                disabled={loading || disabled}
                variant="contained"
              >
                Accept{" "}
                {lead?.lastCounter?.amount
                  ? formatPrice(lead?.lastCounter?.amount)
                  : formatPrice(lead?.listedPrice)}
              </Button>
              <Button
                onClick={() => {
                  setOpen(true)
                }}
                disabled={loading || disabled}
                variant="outlined"
                sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
              >
                New Bid
              </Button>
              <Button
                onClick={onDeniey}
                disabled={loading || disabled}
                variant="text"
                color="error"
                sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
              >
                Deny
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
      <ConfirmModal
        loading={loading || disabled}
        desc={"Are you sure you would like to accept the sellers price?"}
        onConfirm={onAccept}
        open={isOpen}
        setOpen={setIsOpen}
        title={"Accept Sellers Price"}
        noExit
      />
    </>
  )
}
