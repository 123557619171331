import { Box, Button, Stack } from "@mui/material"
import axios from "axios"
import React from "react"
import { useState } from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import PricesInput from "../inputs/PricesInput"
import ConfirmModal from "../modals/ConfirmModal"

export default function DeniedActions({
  setLeads,
  lead,
  setProgress,
  getLeads,
}) {
  const { loading, setLoading, setShow, setErr, disabled } = useData()
  const [open, setOpen] = useState(false)
  const [counter, setCounter] = useState()
  const [isOpen, setIsOpen] = useState(false)

  const onAccept = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/accept`,
        { denied: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setIsOpen(false)
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onCounter = async () => {
    if (!counter || counter === 0) {
      setErr("Please add a offer amount")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/counter`,
        { counter, denied: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onHide = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/hide`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "15% !important" },
          px: { xs: 5, md: 2 },
          py: { xs: 2, md: 1 },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {open ? (
          <Stack
            width={"100%"}
            direction={{ xs: "row", md: "column" }}
            spacing={2}
          >
            <PricesInput
              lable="Make Bid"
              onValueChange={setCounter}
              value={counter}
              //sx={{ maxWidth: "140px" }}
              disabled={loading || disabled}
            />
            <Button
              onClick={onCounter}
              disabled={loading || disabled}
              variant="outlined"
            >
              Send
            </Button>
            <Button
              onClick={() => {
                setOpen(false)
              }}
              disabled={loading}
              variant="text"
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        ) : (
          <Stack direction={{ xs: "row", md: "column" }} spacing={2}>
            <Button
              sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
              onClick={() => setIsOpen(true)}
              disabled={loading || disabled}
              variant="contained"
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                setOpen(true)
              }}
              disabled={loading || disabled}
              variant="outlined"
              sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
            >
              Bid Now
            </Button>
            <Button
              onClick={onHide}
              disabled={loading || disabled}
              variant="text"
              color="error"
              sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
            >
              Delete
            </Button>
          </Stack>
        )}
      </Box>
      <ConfirmModal
        loading={loading || disabled}
        desc={"Are you sure you would like to accept the sellers price?"}
        onConfirm={onAccept}
        open={isOpen}
        setOpen={setIsOpen}
        title={"Accept Sellers Price"}
        noExit
      />
    </>
  )
}
